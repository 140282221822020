<template>
  <b-card body-class="pt-0">
    <template #header>
      <h5 class="text-dark">Payment Instructions</h5>
    </template>
    <div class="row mt-0">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div v-if="current">
          <template v-if="current.isComponent">
            <component :is="current.content" :params="current.props"/>
          </template>
          <div v-else>
            <div class="text-center">
              <h4 class="font-weight-bold text-black">{{ current.content }}</h4>
            </div>
          </div>
          <div v-if="current.isQuestion" class="row">
            <div class="col">
              <b-button class="btn btn-danger btn-block font-weight-bold" @click="setCurrent('yes')">YES</b-button>
            </div>
            <div class="col">
              <b-button class="btn btn-primary btn-block font-weight-bold" @click="setCurrent('no')">NO</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-center">
        <b-button class="btn btn-primary font-weight-bold" @click="reload">Reload</b-button>
      </div>
    </div>
  </b-card>
</template>
<script>

import TransferWise_C1 from "@/components/PaymentsGateway/TransferWise_C1";
import TransferWise_C2 from "@/components/PaymentsGateway/TransferWise_C2";
import TransferWise_C3 from "@/components/PaymentsGateway/TransferWise_C3";
import TransferWise_C4 from "@/components/PaymentsGateway/TransferWise_C4";
import TransferwisePaymentInfo from "@/components/PaymentsGateway/TransferWise_Payment_Info";

export default {
  name: "TransferWise",
  components: {
    TransferWise_C1,
    TransferWise_C2,
    TransferWise_C3,
    TransferWise_C4,
    TransferwisePaymentInfo
  },
  props: {
    order: {default: null},
    intentPayment: {default: null},
  },
  data: function () {
    return {
      information: {
        content: "Do you use WISE (Transferwise)?",
        isComponent: false,
        isQuestion: true,
        yes: {
          content: "TransferwisePaymentInfo",
          isComponent: true,
        },
        no: {
          content: "Do you have a bank account in Austria, Belgium, Cyprus, Estonia, Finland, France, Germany, Greece, Ireland, Italy," +
            "Latvia, Lithuania, Luxembourg, Malta, the Netherlands, Portugal, Slovakia, Slovenia or Spain?",
          isComponent: false,
          isQuestion: true,
          yes: {
            content: "TransferWise_C1",
            isComponent: true,
          },
          no: {
            content: "Are you located in Australia, Canada, UK, New-Zealand, Singapore, USA, Hungary or Turkey?",
            isComponent: false,
            isQuestion: true,
            yes: {
              content: "TransferWise_C2",
              isComponent: true,
            },
            no: {
              content: "TransferWise_C3",
              props: {order: this.order, intentPayment: this.intentPayment},
              isComponent: true,
            }
          }
        }
      },
      current: null
    }
  },
  methods: {
    setCurrent(path) {
      this.current = this.current[path];
    },
    reload() {
      this.current = null
      this.current = this.information;
    }
  },
  mounted() {
    this.current = this.information;
  }
}
</script>

<style scoped lang="scss">
h6 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
