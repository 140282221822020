<template>
  <b-card>
    <div class="container_method_payment">
      <div><img alt="cards" :src="require('@/assets/images/banks/revolut.png')" title="REVOLUT BANK" width="150"></div>
    </div>
  </b-card>
</template>

<script>


export default {
  name: "Binance",

}
</script>

<style scoped lang="scss">
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #c93131; /* Blue */
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_method_payment {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
</style>
