<template>
  <div>
    <p>WISE offers a free bank account in your country.
      <br>
      You can top-up that balance in your local currency using bank transfer or other methods. Free & instant.</p>
    <br>
    <p>
      <span class="font-weight-bold text-black">Option 1 :</span>
      Quick&free and just 0,4% exchange commission<span @click="c1 = true" v-if="!c1"
                                                        class="readMore">... read more </span>
    </p>
    <b-collapse v-model="c1">
      1) Exchange your local currency to EUR for just 0,4% commission.
      <br>
      2) Pay TVS using WISE. It is instant & free.
      <br>
      <span @click="c1 = false" v-if="c1" class="readMore">... show less </span>
    </b-collapse>
    <p class="mt-2">
      <span class="font-weight-bold text-black">Option 2 :</span>
      Slow, medium cost, but minimal exchange commission<span @click="c2 = true" v-if="!c2" class="readMore">... read more </span><br>
    </p>
    <b-collapse v-model="c2" class="mt-2">
      <p>
        1) Exchange your local currency to EUR using your local bank, or a local broker.
        <br>
        2) Send these EUR to your new WISE EUR account (<span class="text-danger">NOT to TVS!!</span>)
        <br>
        3) Pay TVS using WISE. It is instant & free.
      </p>
      <br>
      <span class="pt-4 text-muted" style="font-size: .8em">
        Note:
        Sending EUR directly to TVS results in incorrect payment amount due to bank fees, your payment will
        not be recognized by our system.
      </span>
      <br>
      <span @click="c2 = false" v-if="c2" class="readMore">... show less </span>
    </b-collapse>
    <div class="mt-4">
      <transferwise-payment-info class="mt-2" :order="order" :show-address="false"/>
    </div>
  </div>
</template>

<script>
import TransferwisePaymentInfo from "@/components/PaymentsGateway/TransferWise_Payment_Info";

export default {
  name: "TransferWise_C2",
  components: {
    TransferwisePaymentInfo
  },
  data: () => ({
    c1: false,
    c2: false
  }),
}
</script>

<style scoped>

</style>
