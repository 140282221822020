<template>
  <div class="w-100" :class="{'disabled_div': order.emailSent !== null}" v-show="canShowDetailsPayments">
    <b-card body-class="pt-0 pb-2 table-responsive heart">
      <template #header>
        <h5 class="text-dark">Bank transfer options</h5>
      </template>
      <table class="table table-bordered table-sm">
        <thead>
        <tr>
          <th>Account currency</th>
          <th>Currency fee</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr @click="openModal(item.prefix)" v-for="(item, index) in countries" :key="index">
          <td>
            <div class="row">
              <div class="col-lg-1 col-md-2 col-sm-12">
                <div class="glps-circle">
                  <img :src="item.flag" class="flags">
                </div>
              </div>
              <div class="col-lg-11 col-md-8 col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-sm-12">
                        <span class="small" style="min-width: 5rem">{{ item.prefix }}</span>
                      </div>
                      <div class="col-lg-10 col-md-10 col-sm-12">
                        <span class="ml-2 local_badge font-weight-bold small">{{ item.badge }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="small">{{ item.header_title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <span class="small">{{ item.fee }}%</span>
          </td>
          <td class="text-center"><span class="small" style="color:#399bda;">View details</span></td>
        </tr>

        </tbody>
      </table>
    </b-card>

    <b-card class="mt-2 heart">
        <h5>Card options</h5>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center"
             @click="showPayment('card')"
             style="cursor: pointer">
          <img :src="require('@/assets/images/payoneer-flags/credit_card.png')" alt="stripe" style="width: 8rem"
               :style="{
            border: control_cards.selected == 'card'? '0.1rem solid #d14343': '',
            padding: control_cards.selected == 'card'? '0.3rem': ''
          }">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center" @click="showPayment('ideal')" style="cursor: pointer">
          <img  alt="cards" :src="require('@/assets/images/banks/ideal.svg')" style="width: 6rem":style="{
            border: control_cards.selected == 'ideal'? '0.1rem solid #d14343': '',
            padding: control_cards.selected == 'ideal'? '0.3rem': ''
          }">
        </div>
      </div>
      <div class="row mt-2" v-if="control_cards.visa">
        <div class="col">
          <span class="font-weight-bolder">Visa takes 0,4 exchange rate commission.</span> <br>
          <span class="font-weight-bolder">Some banks add hidden fees up to 1%.</span>
          <span class="font-weight-bolder">So the total fee from credit card payments may vary between 2.9 and 4%.</span>
        </div>
      </div>
      <stripe class="mt-4" v-if="order" :amount="order.price" :description="descriptionOrder" :order-id="order.id"
              :dealer-id="order.userId"
              :intentPayment="intentPayment" :original-price="order.price" ref="stripe"/>
    </b-card>

    <b-modal id="modal_payoneer" size="lg" @close="closeModal" hide-footer no-close-on-backdrop
             no-close-on-esc>
      <div v-if="country_selected">
        <div class="row justify-content-center" style="background-color: #f1f3f7">
          <div class="col-lg-4 col-md-4 text-right">
            <img :src="country_selected.flag" style="width: 4vw">
          </div>
          <div class="col-lg-4 col-md-4">
            <h4> {{ country_selected.prefix }}</h4>
            <h5> {{ country_selected.header_title }}</h5>
          </div>
          <div class="col-lg-4 col-md-4">
            <h4>Total Price:</h4>
            <h5>
              {{ country_selected.coin }} {{ (recalculatePrice.amount * country_selected.currency.price).toFixed(2) }}
            </h5>
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <ul style="list-style: none" v-if="!['Eurozone','WorldWide'].includes(country_selected.prefix)">
              <li class="font-weight-bold"> Only local <span class="text-danger">{{country_selected.coin.toUpperCase()}}</span> account or use <img :src="require('../../assets/images/banks/wise_small.png')" alt="" style="width: 4rem;">/Revolut etc.</li>
              <li class="font-weight-bold">Payments from SWIFT and/or personal account gets rejected.</li>
            </ul>
            <ul style="list-style: none" v-if="country_selected.prefix ==='Eurozone'">
              <li class="font-weight-bold"> Only IBAN to IBAN or use  <img :src="require('../../assets/images/banks/wise_small.png')" alt="" style="width: 4rem;">/Revolut etc.</li>
              <li class="font-weight-bold">Payments from SWIFT and/or personal account gets rejected.</li>
            </ul>
            <ul style="list-style: none" v-if="country_selected.prefix ==='WorldWide'">
              <li class="font-weight-bold"> You can pay with your personal or business account.  </li>
            </ul>
          </div>
          <div class="col-lg-12 col-sm-12">
            <b-form-checkbox
              id="checkbox_term_payoneer"
              v-model="isSelectedCheckbox"
              name="checkbox_term_payoneer">
              <span class="font-weight-bold text-danger"> {{country_selected.confirm_text}}</span>
            </b-form-checkbox>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-2">
            <h6>Account details</h6>
          </div>
          <div class="col" v-show="isSelectedCheckbox">
            <span @click="copyToClipboard" style="cursor:pointer; color: #399bda"> <i class="fa fa-copy"></i> Copy account details</span>
          </div>
        </div>
        <div class="row" v-for="(item, index) in country_selected.content_section_tree" :key="index">
          <div class="col-lg-2">
            <strong>{{ item.title }}: </strong>
          </div>
          <div class="col">
            <span v-show="isSelectedCheckbox">{{ item.text }}</span>
            <span v-show="!isSelectedCheckbox">***************</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="text-center">
              <button class="btn btn-primary" @click="openModalSendInvoice">SEND INVOICE</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Stripe from "@/components/PaymentsGateway/Stripe";
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import {mapGetters, mapMutations} from "vuex";
import {get, post} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";
export default {
  name: "Payoneer",
  components: {
    Stripe,
    CurrencyPriceView,
  },
  mixins:[show_alert_mixin],
  props: {
    order: {
      default: null,
      required: true
    },
    descriptionOrder: {
      default: null
    },
    intentPayment: {
      default: null,
      required: true
    },
    originalPrice: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapGetters('paymentGateway', ['recalculatePrice']),
    currencyDetails() {
      let currency = this.order.currencyDetails;
      if (this.order.ownerId == null) {
        currency = this.$store.state.geo.tvsCurrency
      }
      return currency
    },
    canShowDetailsPayments() {
      return (this.$userCan(['distributor', 'dealer']) && this.intentPayment === null) ||
        (this.$userCan(['distributor', 'dealer']) && this.intentPayment !== null && !['succeeded', 'processing'].includes(this.intentPayment.status))
    }
  },
  mounted() {
    this.getCurrencies()
    this.deleteHeart()
  },
  destroyed() {
    this.$store.commit('paymentGateway/setCountrySelectedCurrency', null);
  },
  data: () => ({
    isSelectedCheckbox: false,
    country_selected: null,
    countries: [
      {
        flag: require('../../assets/images/payoneer-flags/worldwide.png'),
        prefix: 'WorldWide',
        coin: 'USD',
        currency: null,
        price: 0,
        fee: 1,
        badge: 'SWIFT (Wire)',
        header_title: 'USD | XX-0048',
        header_subtitle: 'USD Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text: [
          {text: 'Use for international or Fedwire transfers in USD via the SWIFT network', checked: true},
          {text: 'Only payments for business transactions from business accounts are supported', checked: true},
        ],
        content_section_two: [
          {text: 'Transfers usually take 1-3 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree: [
          {title: 'Bank name', text: 'First Century Bank'},
          {title: 'Transfer type', text: 'Wire (SWIFT) transfer'},
          {title: 'Bank Address', text: '1731 N Elm St  Commerce, GA 30529 USA'},
          {title: 'Routing (ABA)', text: '061120084'},
          {title: 'Account number', text: '4050000000048'},
          {title: 'Account type', text: 'CHECKING'},
          {title: 'Beneficiary name', text: 'TVS Group International  S A'},
        ],
        confirm_text: 'Confirm you understand that you can pay with your business or personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/australian.png'),
        prefix: 'Australia',
        coin: 'AUD',
        currency: null,
        price: 0,
        fee: 2,
        badge: 'Local',
        header_title: 'AUD | XX-2129',
        header_subtitle: 'AUD Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text: [
          {
            text: 'Use for local bank transfers within Australia in AUD via the Direct Entry (BECS) network',
            checked: true
          },
          {text: 'Only payments for business transactions from business accounts are supported', checked: true},
          {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
        ],
        content_section_two: [
          {text: 'Transfers usually take 1-3 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree: [
          {title: 'Bank name', text: 'Citibank'},
          {title: 'Branch code (BSB)', text: '248024'},
          {title: 'Account number', text: '10232129'},
          {title: 'Beneficiary name', text: 'TVS Group International'},
          {title: 'Bank address', text: '2 Park Street, Sydney NSW 2000'},
        ],
        confirm_text: 'Confirm you understand that you cannot pay with your personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/canadian.png'),
        prefix: 'Canada',
        coin: 'CAD',
        currency: null,
        price: 0,
        fee: 2,
        badge: 'Local',
        header_title: 'CAD | XX-1900',
        header_subtitle: 'CAD Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text: [
          {text: 'Use for local bank transfers within Canada in CAD via the EFT network', checked: true},
          {text: 'Only payments for business transactions from business accounts are supported', checked: true},
          {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
        ],
        content_section_two: [
          {text: 'Transfers usually take 1-3 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree: [
          {title: 'Bank name', text: 'Digital Commerce Bank'},
          {title: 'Institution number', text: '0352'},
          {title: 'Transit number', text: '20009'},
          {title: 'Account type', text: 'CHECKING'},
          {title: 'Account number', text: '96141731900'},
          {title: 'Beneficiary name', text: 'TVS Group International S A'},
          {title: 'Bank address', text: '736 Meridian Road NE Calgary, Alberta T2A 2N7, Canada'},
        ],
        confirm_text: 'Confirm you understand that you cannot pay with your personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/eu.png'),
        prefix: 'Eurozone',
        coin: 'EUR',
        currency: null,
        price: 1,
        fee: 0,
        badge: 'Local',
        header_title: 'EUR | XX-0534',
        header_subtitle: 'EUR Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text:
          [
            {text: 'Use for local bank transfers within the EU in EUR via the SEPA network', checked: true},
            {text: 'Only payments for business transactions from business accounts are supported', checked: true},
            {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
          ],
        content_section_two: [
          {text: 'Transfers usually take 1-3 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree: [
          {
            title: 'Bank name',
            text: 'Banking Circle S.A.'
          },
          {
            title: 'IBAN',
            text: 'LU174080000097610534'
          },
          {
            title: 'BIC',
            text: 'BCIRLULL'
          },
          {
            title: 'Beneficiary name',
            text: 'TVS Group International  S A'
          },
          {
            title: 'Bank address',
            text: '2 boulvard de la foire, Luxembourg'
          },
        ],
        confirm_text: 'Confirm you understand that you cannot pay with SWIFT or your personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/japan.png'),
        prefix: 'Japan',
        coin: 'JPY',
        currency: null,
        price: 0,
        fee: 2,
        badge: 'Local',
        header_title: 'JPY | XX-5645',
        header_subtitle: 'JPY Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text:
          [
            {text: 'Use for local bank transfers within Japan in JPY via the Zengin System network', checked: true},
            {text: 'Only payments for business transactions from business accounts are supported', checked: true},
            {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
          ],
        content_section_two: [
          {text: 'Transfers usually take 1-2 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree:
          [
            {
              title: 'Bank name',
              text: 'MUFG Bank, Ltd.'
            },
            {
              title: 'Bank code',
              text: '0005'
            },
            {
              title: 'Branch code',
              text: '869'
            },
            {
              title: 'Account type',
              text: 'Savings / Futsu'
            },
            {
              title: 'Bank address',
              text: '7-1 Marunouchi 2-Chome, Chiyoda-ku Tokyo, Japan'
            },
            {
              title: 'Account number',
              text: '2645645'
            },
            {
              title: 'Beneficiary name',
              text: 'ﾍﾟｲｵﾆｱ ｼﾞﾔﾊﾟﾝ(ｶ'
            },
          ],
        confirm_text: 'Confirm you understand that you cannot pay with your personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/singapure.png'),
        prefix: 'Singapore',
        coin: 'SGD',
        currency: null,
        price: 0,
        fee: 2,
        badge: 'Local',
        header_title: 'SGD | XX-6135',
        header_subtitle: 'SGD Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text:
          [
            {
              text: 'Use for local bank transfers within Singapore in SGD via the GIRO/ACH, FAST, or MEPS network',
              checked: true
            },
            {text: 'Only payments for business transactions from business accounts are supported', checked: true},
            {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
          ],
        content_section_two: [
          {text: 'Transfers usually take 1-2 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree:
          [
            {
              title: 'Bank name',
              text: 'Citibank N.A., Singapore Branch'
            },
            {
              title: 'Bank code',
              text: '7214'
            },
            {
              title: 'Branch code',
              text: '001'
            },
            {
              title: 'SWIFT code',
              text: 'CITISGSG'
            },
            {
              title: 'Account number',
              text: '22990776135'
            },
            {
              title: 'Beneficiary name',
              text: 'TVS Group International S A'
            },
            {
              title: 'Bank address',
              text: '8 Marina View #17-01 Asia Square Tower 1 Singapore 018960'
            },
          ],
        confirm_text: 'Confirm you understand that you cannot pay with your personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/emirates.png'),
        prefix: 'UAE',
        coin: 'AED',
        currency: null,
        price: 0,
        fee: 2,
        badge: 'Local',
        header_title: 'AED | XX-3337',
        header_subtitle: 'AED Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text: [
          {text: 'Use for local bank transfers within the UAE in AED via the UAEFTS (RTGS) network', checked: true},
          {text: 'Only payments for business transactions from business accounts are supported', checked: true},
          {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
        ],
        content_section_two: [
          {text: 'Transfers usually take 1-3 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree: [
          {title: 'Bank name', text: 'Standard Chartered'},
          {title: 'SWIFT code', text: 'SCBLAEADXXX'},
          {title: 'IBAN', text: 'AE750446420010001023337'},
          {title: 'Beneficiary name', text: 'TVS Group International'},
          {title: 'Bank address', text: 'Standard Chartered Tower, Emaar Square Dubai, United Arab Emirates'},
        ],
        confirm_text: 'Confirm you understand that you cannot pay with your personal bank account and accept the payment terms.'
      },
      {
        flag: require('../../assets/images/payoneer-flags/uk.png'),
        prefix: 'UK',
        coin: 'GBP',
        currency: null,
        price: 0,
        fee: 2,
        badge: 'Local',
        header_title: 'GBP | XX-0469',
        header_subtitle: 'GBP Account 1',
        content_section_one_title: 'Account guidelines',
        content_section_one_text:
          [
            {
              text: 'Use for local bank transfers within the UK in GBP via the BACS or Faster Payments Service network',
              checked: true
            },
            {text: 'Only payments for business transactions from business accounts are supported', checked: true},
            {text: 'Wire transfers (SWIFT) are automatically rejected', checked: false}
          ],
        content_section_two: [
          {text: 'Transfers usually take 1-3 business days', flag: require('@/assets/images/payoneer-flags/time.png')},
        ],
        content_section_tree:
          [
            {
              title: 'Bank name',
              text: 'Barclays'
            },
            {
              title: 'Sort code',
              text: '231486'
            },
            {
              title: 'Account number',
              text: '11660469'
            },
            {
              title: 'Beneficiary name',
              text: 'TVS Group International S A'
            },
          ],
        confirm_text: 'Confirm you understand that you cannot pay with your personal bank account and accept the payment terms.'
      },
    ],
    currencies: [],
    control_cards: {
      visa: false,
      selected: null
    },
    enable_button_send_invoice: false
  }),
  methods: {
    ...mapMutations(['loaderManager']),
    openModal(key) {
      this.control_cards.visa = false
      this.control_cards.selected = null
      const result = this.countries.find(x => x.prefix === key)
      if (result) {
        this.setValuesModal(result)
      }
    },
    deleteHeart(){
      if(this.order.emailSent !== null){
        document.querySelectorAll(".heart").forEach(el => el.classList.remove('heart'));
        document.querySelectorAll(".addheart").forEach(el => el.classList.add('heart'));
      }
    },

    setValuesModal(result) {
      this.calculatePrice([result.prefix])
      this.$nextTick(() => {
        this.$refs.stripe.cancelCurrentIntentPayment()
      })
      this.country_selected = result
      this.$store.commit('paymentGateway/setCountrySelectedCurrency', result.currency);
      this.$bvModal.show('modal_payoneer')
    },
    copyToClipboard() {
      let copyText = '';
      this.country_selected.content_section_tree.forEach(el => {
        copyText += `${el.title}: ${el.text}\n`
      });
      navigator.clipboard.writeText(copyText);
      this.$swal({
        title: 'Success',
        html: 'Text copied',
        icon: 'success',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    closeModal() {
      this.isSelectedCheckbox = false
    },
    showPayment(type) {
      this.changeheartbeat(2)
      this.$store.commit('paymentGateway/setCountrySelectedCurrency', null);
      this.control_cards.visa = type === 'card'
      this.control_cards.selected = type
      this.$nextTick(() => {
        this.$refs.stripe.initFunctions(type)
      })
    },
    calculatePrice(type) {
      this.$store.commit('paymentGateway/setPaymentMethodTypes', type);
      this.$store.commit('paymentGateway/setAmountOriginal', this.originalPrice);
    },
    async getCurrencies() {
      const {data} = await get('geo/currency-current', null, false)
      this.countries.forEach(item => {
        const res = data.find(x => x.currency_code === item.coin)
        if (res) {
          item.currency = {
            code: res.currency_code,
            symbol: res.currency_symbol,
            name: res.currency_name,
            price: res.currency_price,
            locale: res.locale
          }
          item.price = Number(res.currency_price).toFixed(2)
        }
        if (item.prefix === 'WorldWide') {
          const response = data.find(x => x.currency_code === 'USD')
          item.currency = {
            code: "USD",
            symbol: "$",
            name: "United State Dollar",
            price: response.currency_price,
            locale: "en-AS"
          }
        }
      })
    },
    changeheartbeat(value){
      document.querySelectorAll(".heart").forEach(el => el.classList.remove('heart'));
      this.$store.commit('paymentGateway/setPaymentSelected', value);
    },

    openModalSendInvoice(){
      this.$bvModal.hide('modal_payoneer')
      const section = '<div style="text-align: justify;"><p>You are about to generate your invoice and you will not be able to change the selected currency and method anymore.</p> <br>' +
        '<p>Please note that the <span class="text-danger">EURO</span> pricing is leading. Show local currency is based on the <span class="text-danger">CURRENT</span> exchange rate. Payment which are not done instantly might / will be incorrect due a change in currency exchange rate.</p> <br>' +
        '<p>If you are not going to pay instantly or are not sure if the correct payment method was selected, click cancel to close this window and come back later.</p>' +
        '</div>';
      this.$swal({
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: true,
        title: `Are you sure you want to pay in ${this.country_selected.coin.toUpperCase()}?`,
        html: section,
        customClass: 'swal-wide',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "GENERATE INVOICE",
        cancelButtonText: "CANCEL",
      }).then((value) => {
        if (value.isConfirmed) {
          this.sendInvoice(),
          this.changeheartbeat(1)
        }
      })
    },
    async sendInvoice() {
      this.enable_button_send_invoice = true
      this.loaderManager(true);
      const fee = (this.recalculatePrice.percentageFee > 0) ? this.recalculatePrice.amount - this.recalculatePrice.amountOriginal : 0
      await post('credit-order/add-extra-fee', {id: this.order.id, fee: fee}, true).then();
      post(`credit-order/send-invoice-order-create/${this.order.id}`, {id: this.order.id}, true).then((data) => {
        this.loaderManager(false);
        this.showAlertNotification("A copy of your invoice has been sent to your email address")
        setTimeout(() => {
          location.reload()
        }, 2000)
      })
    }
  }
}
</script>

<style scoped lang="scss">
table tr {
  cursor: pointer;
}
.local_badge {
  background: #8c98ab;
  color: white;
  padding: 0.2rem;
}
.flags {
  //width: 20px;
  //height: 16px;
  width: 1.2rem;
  height: 1.1rem;
  margin: 6px 4px;
}
.glps-circle {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  border-radius: 50%;
  border: solid 1px #8c98ab;
  margin-top: 11px;
}
.disabled_div {
  pointer-events:none;
  opacity: 0.5;
}

</style>
