<template>
  <div>
    <h6 class="font-weight-bold text-black">In General you have 4 options:</h6>
    <p>
      <span class="font-weight-bold text-black">Option 1: </span> High cost, instant, safe : Credit card<span
      @click="c1 = true" v-if="!c1" class="readMore">... read more </span>
    </p>

    <b-collapse v-model="c1" class="">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
          Pay instantly with your credit card. Please select payment processor: <br>
          <b-row class="my-3">
            <b-col lg="4">
              <div v-b-toggle.payment @click="showPayment('wise')" class="btn btn-outline-light">
                <img :src="require('@/assets/images/banks/wise_small.png')" alt="wise"
                     style="height: 30px; margin: 6px 0" class="img-fluid">
              </div>
            </b-col>
            <b-col lg="5">
              <div @click="showPayment('stripe');" class="btn btn-outline-light ml-5">
                <img :src="require('@/assets/images/banks/stripe_small.png')" alt="stripe" style="height: 45px"
                     class="img-fluid">
              </div>
            </b-col>
          </b-row>
          <b-collapse v-model="showPaymentValue.showContainer">
            <template v-if="showPaymentValue.wise">
              <transferwise-payment-info ref="wise" :show-logo="false"/>
              <div class="text-left mt-1" style="text-decoration: underline solid rgb(42 65 105);">
                <a href="https://wise.com" target="_blank" class="font-weight-bold btn-link">Pay with WISE</a>
              </div>
            </template>
            <template v-else>
              <stripe :amount="order.price" :description="descriptionOrder" :order-id="order.id"
                      :dealer-id="order.userId"
                      :intentPayment="intentPayment" :original-price="order.price" ref="stripe"/>
            </template>
          </b-collapse>
          <p class="notes pt-4">
            Notes:
            <br>
            Visa takes 0,4 exchange rate commission.
            <br>
            Some banks add hidden fees up to 1%. So the total fee from credit card payments may vary between
            2.9 and 4%.
          </p>
          <span @click="c1 = false" v-if="c1" class="readMore">... show less </span>
        </div>
      </div>
    </b-collapse>
    <p class="mt-2">
      <span class="font-weight-bold text-black">Option 2: </span>Medium cost, slow, risky : SWIFT<span
      @click="c2 = true" v-if="!c2" class="readMore">... read more </span>
    </p>
    <b-collapse v-model="c2">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          1) Exchange your local currency to EUR using your local bank, or a local broker.
          <br>
          2) Send these EUR to TVS using SWIFT international payment. Fee = €20-80
          <br>
          <p class="notes pt-4">
            Note: Your bank uses high hidden fees. TVS will not receive the correct amount. Your payment will not be
            recognized by our system.
          </p>
          <span @click="c2 = false" v-if="c2" class="readMore">... show less </span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
          <transferwise-payment-info :show-address="true"/>
        </div>
      </div>
    </b-collapse>
    <p class="mt-2">
      <span class="font-weight-bold text-black">Option 3: </span>Low cost, slow, accurate : SWIFT + WISE<span
      @click="c3 = true" v-if="!c3" class="readMore">... read more </span>
    </p>
    <b-collapse v-model="c3">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          WISE offers a free EUR-bank account in most countries. To top up your EUR balance:
          <br>
          1) Exchange your local currency to EUR using your local bank, or a local broker.
          <br>
          2) Send these EUR to your new WISE EUR account. Fee = €21-35
          <br>
          3) Pay TVS using WISE. It is instant & free.
          <br>
          <span @click="c3 = false" v-if="c3" class="readMore">... show less </span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mt-2">
          <transferwise-payment-info :show-address="false"/>
        </div>
      </div>
    </b-collapse>
    <p class="mt-2">
      <span class="font-weight-bold text-black">Option 4: </span>On request only : Cryptocurrency<span
      @click="c4 = true" v-if="!c4" class="readMore">... read more </span>
    </p>
    <b-collapse v-model="c4">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          Create new support ticket for more information.
          <span @click="c4 = false" v-if="c4" class="readMore">... show less </span>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import TransferwisePaymentInfo from "@/components/PaymentsGateway/TransferWise_Payment_Info";
import Stripe from "@/components/PaymentsGateway/Stripe";
import {CREDIT_ORDERS_STATUS} from '@/constants';
import {get} from "@/services/api";

export default {

  name: "TransferWise_C1",
  components: {
    TransferwisePaymentInfo,
    Stripe
  },
  props: {
    params: {
      order: null,
      intentPayment: null
    }
  },
  data: function () {
    return {
      CREDIT_ORDERS_STATUS: CREDIT_ORDERS_STATUS,
      order: this.params.order,
      descriptionOrder: null,
      showDivPayment: true,
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      showPaymentValue: {
        showContainer: false,
        wise: false,
        stripe: false
      }
    }
  },
  mounted() {
    if (!!this.params.intentPayment) {
      this.showPaymentMethod()
    }
    this.descriptionOrder = `${this.order.credit} software credits. incl. ${this.order.discount}% discount`
    if (this.$route.query.payment_intent !== undefined) {
      this.getDetailIntentPayment()
    }
  },
  methods: {
    showPayment(method) {
      this.showPaymentValue = {
        showContainer: true,
        wise: false,
        stripe: false
      }
      this.showPaymentValue[method] = true;
      this.$nextTick(() => {
        if (this.showPaymentValue.stripe) {
          this.$refs.stripe.initFunctions('card')
        }
      })
    },
    async redirectTo() {
      window.open('https://wise.com', '_blank');
    },
    getDetailIntentPayment() {
      get("payment-gateway", this.$route.query.payment_intent, true).then(({data}) => {
        if (data.status === "succeeded") {
          this.showMessage('Payment successful', 'Credits added your account.', 'success')
        } else if (data.status === 'processing') {
          this.showMessage('Your payment is processing', 'We will notify you when the payment is confirmed', 'info')
        } else if (data.status === 'requires_payment_method') {
          this.showMessage('Something went wrong', 'Please try again', 'warning')
        } else {
          this.showMessage('Something went wrong', 'Please try again', 'warning')
        }
      })
    },
    async showMessage(title, text, icon) {
      await this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    showPaymentMethod() {
      this.showDivPayment = this.order && this.order.owner === null && this.params.intentPayment.status !== 'succeeded' && this.params.intentPayment.status !== 'processing'
    }
  }
}
</script>

<style scoped>

</style>
