<template>
  <b-modal ref="modal" dialog-class="modal-dialog-centered"
           :title="!isNextPayment? 'Do you want to confirm the Payment?' : 'Register payment'"
           ok-title="Confirm" cancel-title="CANCEL" cancel-variant="danger" @cancel="closeModel" @ok="handleOk">
    <validation-observer v-slot="{handleSubmit}" ref="form">
      <form @submit.prevent="handleSubmit(save)">
        <div class="form-group" v-if="!isNextPayment">
          <b-form-checkbox v-model="model.is_payment_complete" switch>
            Has the customer paid the total for the credit order? {{ model.is_payment_complete ? 'Yes' : 'No' }}
          </b-form-checkbox>
        </div>
        <validation-provider v-if="isNextPayment || !model.is_payment_complete" :rules="`required|max_value:${max}`"
                             v-slot="{errors}" name="amount">
          <div class="form-group">
            <label>Amount received ({{ currencyForTransaction.symbol }})</label>
            <b-input :state="!errors.length" v-model.number="model.amount" type="number"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <label class="form-control" v-if="!model.is_payment_complete && !model.add_all_credits">
          Possible credits to add to the user's account: <b class="pull-right">
          <credits-view :amount="calculateCreditsToSum" :currency-details="order.currencyDetails"/>
        </b>
        </label>
        <div class="form-group">
          <label>Comment (optional)</label>
          <b-form-textarea v-model="model.comment"></b-form-textarea>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {post} from '@/services/api';
import {mapGetters, mapState} from 'vuex';
import {UserRole} from "@/utils/auth.roles";
import CreditsView from "@/components/common/CreditsView";

export default {
  name: 'ConfirmPayment',
  components: {CreditsView},
  computed: {
    ...mapGetters('geo', ['localCreditsToTvsCredits', 'currencyCreditSymbol', 'creditsToLocalCredits', 'tvsCreditsToLocalCredits',
      'getCurrentCurrencyUsed', 'getCurrencyByCountry', 'numberFilter', 'formatToCurrency']),
    ...mapState('geo', ['showInLocal', 'tvsCurrency', 'userCurrency']),
    calculateCreditsToSum() {
      const credit = this.order.credit;
      const price = this.order.price;
      const factor = Number((price / credit));
      return this.localCreditsToTvsCredits(this.model.amount / factor, this.currencyForTransaction);
    },
    symbol() {
      const code = this.currencyUserOrder.code.toLowerCase();
      try {
        return require(`@/assets/images/credits/${code}.svg`);
      } catch (e) {
        return require(`@/assets/images/credits/default.svg`);
      }
    },
    currencyUserOrder() {
      if (this.showInLocal) {
        return this.order.currencyDetails
      } else {
        return this.tvsCurrency
      }
    },

    currencyForExchange() {
      let currency = {...this.tvsCurrency, locale: this.userCurrency.locale};
      if (this.$userCan(['distributor', 'dealer', 'subdealer']) && this.showInLocal) {
        currency = this.userCurrency;
      }
      return currency;
    },
    // symbol() {
    //   const code = this.currencyForExchange.code.toLowerCase();
    //   try {
    //     return require(`@/assets/images/credits/${code}.svg`);
    //   } catch (e) {
    //     return require(`@/assets/images/credits/default.svg`);
    //   }
    // }

  },
  data: () => ({
    isNextPayment: false,
    max: 0,
    model: {
      orderId: null,
      is_payment_complete: true,
      add_all_credits: false,
      amount: null,
      extraFee: null,
      comment: null
    },
    order: null,
    currencyForTransaction: null
  }),
  methods: {
    open(order, totalPending = false) {
      this.$emit("changeStatusButton", true)
      this.currencyForTransaction = {...order.currencyDetails, locale: this.userCurrency.locale};
      if (this.$userCan([UserRole.Admin])) {
        this.currencyForTransaction = this.$store.state.geo.tvsCurrency;
      }
      this.order = order;
      this.model.extraFee = order.extraFee
      if (totalPending !== false) {
        this.max = this.tvsCreditsToLocalCredits(totalPending, order.currencyDetails);
        this.model.amount = this.tvsCreditsToLocalCredits(totalPending, order.currencyDetails);
      } else {
        this.max = this.tvsCreditsToLocalCredits(order.price, order.currencyDetails);
        this.model.amount = this.tvsCreditsToLocalCredits(order.price, order.currencyDetails);
      }
      this.model.orderId = order.id;
      this.$refs.modal.show();
    },
    async save() {
      this.$refs.modal.hide();
      this.$store.commit('loaderManager', true);
      const dataToSave = {
        ...this.model, amount: this.localCreditsToTvsCredits(this.model.amount, this.currencyForTransaction)
      };
      post('credit-order/confirm', dataToSave, true)
        .then((data) => {
          this.$store.dispatch('buySale/loadInformation');
          this.$swal({
            title: 'Success',
            html: !this.isNextPayment ? `<img src="${this.symbol}" style="width: 25px; padding-bottom: 5px" alt="">
            ${this.numberFilter(this.tvsCreditsToLocalCredits(this.calculateCreditsToSum, this.currencyUserOrder, true))}
            credits have been added to the client’s balance` : 'The payment has been added.',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
          }).then();
        }).finally(() => {
        this.$store.commit('loaderManager', false);
        setTimeout(() => {
          location.reload()
        }, 3000)
      }).catch(e => {
        if (e.toString().includes('404')) {
          this.$router.push({name: 'credit-price', query: {from: `credit-order-detail/${this.order.id}`}})
        }
      });
    },
    handleOk(bvModalEvt) {
      if (Number(this.model.amount) > 0) {
        bvModalEvt.preventDefault();
        this.$refs.form.validate().then(success => {
          if (success) {
            this.$swal({
              html: `<h4><span>Do you want to confirm the payment?</span> <br>
                      Paid: <span style="color: #d14343">${this.formatToCurrency(this.model.amount + this.model.extraFee, this.currencyForTransaction)}</span> <br>
                      Credits:  <span  style="color: #d14343">
                       <img src="${this.symbol}" style="width: 25px; padding-bottom: 5px" alt=""/>
                       ${this.numberFilter(this.calculateCreditsToSum)}
                    </span> </h4>`,
              showCancelButton: true,
              cancelButtonText: `Cancel`,
              confirmButtonText: `OK`,
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000000',
            }).then(async (result) => {
              if (result.isConfirmed) {
                this.save().then();
              }
            });
          }
        });
      }
    },
    closeModel(){
      this.$emit("changeStatusButton", false)
    }
  },
};
</script>

<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #343a40 !important;
  color: #343a40 !important;
  background: #343a40 !important;
}

.custom-switch .custom-control-label::before {
  background-color: #d14343 !important;
}

.custom-switch .custom-control-label::after {
  background-color: #d14343 !important;
}
</style>
