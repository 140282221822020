<template>
  <b-card v-if="showDiv">
    <div class="d-flex justify-content-between" style="cursor: pointer;" v-b-toggle.collapse-binance>
      <img alt="cards" :src="require('@/assets/images/binance.png')" title="BINANCE" width="20%">
      <i class="fa fa-arrow-down" aria-hidden="true"></i>
    </div>
    <b-collapse id="collapse-binance" class="mt-2">
      <p>If you want do to a (instant) payment with Binance please contact us and we will send you a QR code payment
        link.
        The fees are zero.</p>
      <p class="mt-2"> You can deposit your Binance balance in your own currency using Binance or Coinbase or other
        brokers,
        using bank transfer or other methods depending on your country. You will then pay to TVS using digital dollars
        like BUSD.</p>
    </b-collapse>
  </b-card>
</template>

<script>


export default {
  name: "Binance",
  props:{
    intentPayment: {
      default: null
    }
  },
  data: () => ({
    showDiv: true,
  }),
  mounted() {
    if (this.intentPayment !== null) {
      this.showDiv = this.intentPayment.status !== "succeeded";
    }
  },
}
</script>

<style scoped lang="scss">
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #c93131; /* Blue */
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container_method_payment {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
