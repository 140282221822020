<template>
  <b-card body-class="pt-0">
    <template #header>
      <h5 class="text-dark" v-if="order.status === CREDIT_ORDERS_STATUS.Offer.toString()">Offer Detail</h5>
      <h5 class="text-dark" v-else>Order Summary</h5>
    </template>
    <div>
      <div class="d-flex justify-content-between mb-2"><b class="text-dark">Created:</b>
        <span>{{ order.createdAt | localeDate }}</span></div>
    </div>
    <div v-if="order.status !== CREDIT_ORDERS_STATUS.Offer.toString()">
      <div class="d-flex justify-content-between" v-if="(this.order.ownerId === null)">
        <b class="text-dark">Invoice Number:</b><span>{{ order.invoiceNumber }}</span></div>
      <div class="d-flex justify-content-between" v-else><b class="text-dark">Order confirmation:</b><span>{{ order.invoiceNumber }}</span></div>
    </div>
    <div
      v-if="order.status.toString() === CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer.toString() && !order.payments.length">
      <div class="d-flex justify-content-between">
        <b class="text-dark">Status:</b>
        <span style="margin: 0" class="text-danger">The platform is confirming the payment</span>
      </div>
    </div>
    <hr class="mt-0 line">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column"><b class="text-dark">Total Credits:</b></div>
      <span class="numbers"><credits-view :amount="order.credit" :currency-details="order.currencyDetails"/></span>
    </div>
    <div class=" d-flex justify-content-between">
      <div class="d-flex flex-column"><b class="text-dark">Price:</b></div>
      <span class="numbers"><currency-price-view :amount="order.price"
                                                 :currency-details="order.currencyDetails"/></span>
    </div>
    <div class=" d-flex justify-content-between">
      <div class="d-flex flex-column"><b class="text-dark">Total Discount:</b></div>
      <span class="numbers">{{ order.discount }}%</span>
    </div>
    <div class=" d-flex justify-content-between" v-if="!$store.state.buySale.information.haveParent">
      <div class="d-flex flex-column"><b class="text-dark">Fee Payment Method:</b></div>
      <span class="numbers">{{ (recalculatePrice.percentageFee).toFixed(1) }}%</span>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column"><b class="text-dark">VAT</b></div>
      <span class="numbers">{{ 0|euro }}</span>
    </div>
    <div class=" d-flex justify-content-between">
      <div class="d-flex flex-column"><b class="text-dark">Total Price EUR:</b></div>
      <currency-price-view :amount="recalculatePrice.amount" :currency-details="currencyDetails" no-switch/>
    </div>
    <div class=" d-flex justify-content-between" v-if="$store.state.paymentGateway.countrySelectedCurrency">
      <div class="d-flex flex-column"><b class="text-dark">Total Price {{$store.state.paymentGateway.countrySelectedCurrency.code}}:</b></div>
      <span> {{$store.state.paymentGateway.countrySelectedCurrency.code}} {{(recalculatePrice.amount * $store.state.paymentGateway.countrySelectedCurrency.price).toFixed(2)}}</span>
    </div>
    <div v-if="order.status ==='Cancelled' && order">
      <hr>
      <h5 class="text-danger">Order cancelled</h5>
    </div>
    <div class=" d-flex justify-content-between" v-if="order.status ==='Cancelled' && order">
      <h5 class="text-danger" v-if="order.orderCancelled">Comment: {{ order.orderCancelled.comment }}</h5>
    </div>
    <div class="" v-if="order.status === 'Offer' && canAcceptOrder && intentPayment === null">
      <button class="btn btn-primary btn-block free-button" @click="acceptOrder(order.id)">Accept Offer</button>
    </div>
    <div class="" v-if="order.owner != null  && $userCan(['administrator'])">
      <p style="margin: 0" class="text-danger">Distributor: {{ order.owner.fullName }}</p>
    </div>
    <hr class="mt-0 line" v-if="intentPayment ">
    <div class=" d-flex justify-content-between" v-if="intentPayment">
      <div class="d-flex flex-column"><b class="text-dark">Status Payment Gateway</b></div>
      <span class="numbers font-weight-bold"
            :class="intentPayment.status === 'succeeded'?'text-success':'text-warning'">{{
          intentPayment.status.toUpperCase()
        }}</span>
    </div>

    <div class="row"
         v-if="order.status === CREDIT_ORDERS_STATUS.New.toString() && $userCan(['distributor','dealer', 'subdealer']) && canAcceptOrder">
      <div class="col-lg-6 col-md-6 col-sm-12" v-if="!$store.state.buySale.information.haveParent">
        <button class="btn btn-danger btn-block font-weight-bold" :disabled="order.emailSent === null"  @click="showModalInvoice">VIEW INVOICE</button>
      </div>
      <div :class="$store.state.buySale.information.haveParent? 'col-lg-12 col-md-12 col-sm-12' :'col-lg-6 col-md-6 col-sm-12'">
        <button v-if="!showFormCompany" :disabled="disableButton || (order.emailSent === null && !$store.state.buySale.information.haveParent)" class=" addheart btn btn-primary btn-block font-weight-bold" @click="confirmPaymentClient(order.id)"><i class="fa fa-credit-card" aria-hidden="true"></i> Confirm payment performed</button>
      </div>
    </div>

    <div v-if="isOwner">
      <h6 v-if="order.status === CREDIT_ORDERS_STATUS.New.toString()" class="text-danger"> The customer has not confirmed the payment of the order.</h6>
      <h6 v-if="order.status === CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer.toString() && !order.payments.length" class="text-success">The customer has already confirmed the payment of the order.</h6>
      <h6 v-if="ownerHaveBalance === false && order.status === CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer" class="text-danger"> You do not have enough credit balance to complete the order.</h6>
      <h6 v-if="ownerHaveBalance === false && order.status === CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer" class="text-danger"> Your current balance:
        <credits-view :amount="information.balance"/>
      </h6>
      <div class="row" v-if="(canAcceptOrder || isOwner) && !order.payments.length">
        <button v-if="$userCan(['administrator']) &&  order.status !== 'Cancelled'" :disabled="disabledButtonAdmin" class="btn btn-primary btn-block free-button" @click="$refs.confirmPayment.open(order)">Confirm Payment</button>
        <button v-if="!$userCan(['administrator']) && ownerHaveBalance === true && order.status === CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer" :disabled="disabledButtonAdmin" class="btn btn-primary btn-block free-button" @click="$refs.confirmPayment.open(order)">Confirm Payment</button>
        <button v-if="ownerHaveBalance === false" class="btn btn-primary btn-block free-button" @click="redirectBuyCredits()">Buy Credits</button>
      </div>
      <div class="row mt-2" v-if="canCancelOrder">
        <button class="btn btn-danger btn-block free-button" @click="cancelCreditOrder(order.id)">CANCEL CREDIT ORDER</button>
      </div>
    </div>
    <div v-else-if="!isOwner && canCancelOrder" class="row mt-2">
      <button class="btn btn-danger btn-block free-button" @click="cancelCreditOrder(order.id)">CANCEL CREDIT ORDER</button>
    </div>
    <ConfirmPayment ref="confirmPayment" @changeStatusButton="changeStatusButton"/>
  </b-card>

</template>

<script>
import {CREDIT_ORDERS_STATUS} from "@/constants";
import {post} from "@/services/api";
import {creditOrderAccept, userAcceptUpdateProfile} from "@/services/endpoint.json";
import {mapGetters, mapMutations, mapState} from "vuex";
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import ConfirmPayment from '@/views/dashboard/credit-order-detail/ConfirmPayment';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "OrderSummary",
  components: {
    CreditsView,
    CurrencyPriceView,
    ConfirmPayment
  },
  props: {
    order: {
      default: {}
    },
    extraFeePaymentMethod: {
      default: 0
    },
    totalPriceOrder: {
      default: 0
    },
    currencyDetails: {
      default: null
    },
    canAcceptOrder: {
      default: true
    },
    intentPayment: {
      default: null
    },
    showFormCompany: {
      default: false
    },
    isOwner: {
      default: false
    },
    ownerHaveBalance: {
      default: false
    },
  },
  mixins:[show_alert_mixin],
  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('paymentGateway', ['recalculatePrice','getCountrySelectedCurrency','paymentSelectedStatus']),
    count(){
      if(this.paymentSelectedStatus == 1){
        this.paymentSelectedHeartClass = this.paymentSelectedStatus
        document.querySelectorAll(".addheart").forEach(el => el.classList.add('heart'));
      }else{
        document.querySelectorAll(".addheart2").forEach(el => el.classList.add('heart'));
      }
    }
  },

  watch: {
    count(){}
  },

  mounted() {
    this.allowCancelCreditOrder()
    if (this.order.extraFee > 0) {
      this.$store.state.paymentGateway.percentageFee = (this.order.extraFee / this.order.price) * 100
      this.$store.state.paymentGateway.amount = this.order.price + this.order.extraFee
      this.$store.dispatch('paymentGateway/calculate')
    }
  },
  data: () => ({
    CREDIT_ORDERS_STATUS: CREDIT_ORDERS_STATUS,
    disableButton: false,
    disabledButtonAdmin: false,
    canCancelOrder: false,
    paymentSelectedHeartClass: false,
  }),
  methods: {
    acceptOrder(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to accept the offer?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async res => {
        if (res.isConfirmed) {
          await post(creditOrderAccept, {id}, true);
          this.order.status = 'New';
          this.showInstruction = true;
          setTimeout(() => {
            location.reload()
          }, 3000)
        }
      });
    },
    async confirmPaymentClient(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to confirm the Payment?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
        .then(async value => {
          if (value.isConfirmed) {
            this.disableButton = true
            this.$store.commit('loaderManager', true);
            post('credit-order/confirm-payment-client', {id}, true)
              .then(() => {
                this.$swal({
                  title: 'Success',
                  text: 'TVS will verify and confirm your payment.',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#d14343',
                });
              })
              .finally(() => {
                this.$store.commit('loaderManager', false);
                setTimeout(() => {
                  location.reload()
                }, 3000)
              });
          }
        });
    },
    redirectProfile() {
      this.$router.push({name: 'profile', query: {order: this.order.id}})
    },
    async redirectBuyCredits() {
      this.$router.push({
        name: 'order-credit',
        query: {parent: this.haveParent}
      }).then().catch();
    },
    showModalInvoice() {
      if(this.order.emailSent)
        this.$emit('showModalInvoice')
      else
        this.showAlertNotification("Select payment method", "info")
    },
    changeStatusButton(status) {
      this.disabledButtonAdmin = status
    },
    allowCancelCreditOrder() {
      this.canCancelOrder = (this.$userCan(['administrator']) && !this.order.payments.length &&
        [CREDIT_ORDERS_STATUS.New, CREDIT_ORDERS_STATUS.Offer, CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer].includes(this.order.status))
    },
    async cancelCreditOrder(id) {
      this.$swal({
        title: 'Are you sure?',
        input: 'text',
        inputAttributes: {autocapitalize: 'off'},
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (!!result.value) {
            this.$store.commit('loaderManager', true);
            await post('credit-order/delete-credit-order', {id: id, comment: result.value}, true)
            this.$store.commit('loaderManager', false);
            this.showAlertNotification("Credit order cancelled")
            this.$router.push({name: 'credit-dashboard'})
            setTimeout(() => {
              location.reload()
            }, 2000)
          }
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
