<template>
  <div>
    <p>
      Not having Transferwise account wil result in high cost, inaccurate and slow transfers.
      <br>
      We strongly advice to open a WISE account. It is free and arranged in minutes. Normal activation time
      about 1 day.
    </p>
    <p class="mt-2">
      Pay TVS directly with these EUR using International Bank Transfer(SWIFT).
      <br>
      Normal International Bank Transfer(SWIFT) are non cost-transparent. Your bank takes high fee’s, as
      well as the intermediar bank. Clients are often unaware of the fact their bank is using intermediar
      banks.
    </p>

    <p class="mt-2 text-danger">
      Select the SWIFT Option “OUR”.
    </p>
    <p class="mt-2">
      Note: fees/commission from you bank may strongly vary 11-50usd.
      <br>
      Intermediar banks may take another extra 11-50usd commission.
      <br>
      Our system may not recognize your payment because deviation in received quantity.
      <br>
      Therefore we ALWAYS advice to use WISE.
    </p>
  </div>
</template>

<script>
export default {
  name: "TransferWise_C1"
}
</script>

<style scoped>

</style>
