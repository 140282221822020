<template>
  <div class="row">
    <div class="col-md-12 col-sm-12" :class="'col-lg-'+lefWith">
      <div class="row">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Account holder: </strong>TVS LATAM SAS</span>
        </div>
      </div>
      <div class="row" v-if="showAddress">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Address: </strong>Currently moving. Not available</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Beneficiary Bank: </strong>WISE Europe S.A</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Country Bank: </strong>Belgium</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Swift/BIC:  </strong>TRWIBEB1XXX</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Account Nr/IBAN:  </strong>BE62 9672 5949 4261</span>
        </div>
      </div>
      <div class="row" v-if="order">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Reference:  </strong> Invoice {{order.invoiceNumber}}</span>
        </div>
      </div>
      <div class="row"  v-if="showOur">
        <div class="col">
          <span><strong class="font-weight-bold text-dark">Payment Condition: </strong>OUR</span>
        </div>
      </div>
    </div>
    <div v-if="showLogo" class="col-md-12 col-sm-12 text-center" :class="'col-lg-'+rightWith" @click="redirectTo()" style="cursor: pointer;">
      <img alt="cards" :src="require('@/assets/images/banks/wise.png')" title="BANK TRANSFER" style="width: 50%;">
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferWise_Payment_Info",
  props: {
    order: {
      default: null
    },
    lefWith: {
      default: 6
    },
    rightWith: {
      default: 6
    },
    showAddress:{
      default: false
    },
    showLogo: {
      default: true
    },
    showOur:{
      default: false
    }
  },
  methods:{
    async redirectTo() {
      window.open('https://wise.com', '_blank');
    },
  }
}
</script>

<style scoped>

</style>
