<template>
    <!--  <b-card v-if="showDiv">-->
    <b-overlay :show="showOverlay" rounded="sm">
      <div style="min-height: 200px" v-show="showBtnPay">
        <form id="payment-form-stripe" @submit.prevent="handleSubmit">
          <div id="payment-element"></div>
          <button :disabled="disabled" id="submit" class="btn btn-primary btn-block mt-4 heart addheart2">
            <span id="button-text"><i class="fa fa-credit-card" aria-hidden="true"></i>Pay now</span>
          </button>
        </form>
      </div>
    </b-overlay>
    <!--  </b-card>-->
</template>

<script>
import {post} from "@/services/api";
import {decrypt} from "@/helpers/encrypt";
import {mapGetters, mapState} from "vuex";

const stripe = Stripe(process.env.VUE_APP_STRIPE);

export default {
  name: "Stripe",
  props: {
    items: {
      type: Array,
      default: [{id: 'software credits'}]
    },
    amount: {
      type: Number,
      default: 0
    },
    originalPrice: {
      type: Number,
      default: 0
    },
    extraFeePaymentMethod: {default: 0},
    description: {
      type: String,
      default: null
    },
    orderId: {
      type: Number,
      default: null
    },
    dealerId: {
      type: Number,
      default: null
    },
    intentPayment: {
      type: String,
      default: null
    },
    isEuropa: {
      default: true
    }
  },
  mounted() {
    if (this.intentPayment !== null) {
      this.showDiv = true
      if (this.intentPayment.status !== "succeeded") {
        this.showOverlay = true
        this.filterMethodPayment(this.intentPayment.payment_method)
        this.cancelCurrentIntentPayment().then()
        setTimeout(() => {
          this.showOverlay = false
        }, 2000)
      } else {
        this.showDiv = false
      }
    }
    // this.checkStatus()
  },
  computed: {
    ...mapState(['paymentGateway']),
    ...mapGetters('paymentGateway', ['recalculatePrice']),
    showBtnPay(){
      return  !this.showOverlay && this.intentPayment !== null && this.statusCancel.includes(this.intentPayment.status)
    }
  },
  data: () => ({
    elements: null,
    showDiv: true,
    showOverlay: false,
    clientSecret: null,
    statusCancel: [
      "requires_payment_method", "requires_capture", "requires_confirmation", "requires_action"
    ],
    controlPaymentMethods: {
      card: true,
      eps: true,
      bancontact: true,
      p24: true,
      ideal: true,
      giropay: true,
      sofort: true,
      sepa_debit: true,
      wechat_pay: true,
      alipay: true
    },
    disabled: false
  }),
  methods: {
    async initFunctions(type) {
      this.calculatePrice(type)
      this.showOverlay = true
      this.filterMethodPayment(type)
      this.cancelCurrentIntentPayment().then(() => {
        this.initialize(type).then();
      })
    },
    calculatePrice(type) {
      this.$store.commit('paymentGateway/setPaymentMethodTypes', type);
      this.$store.commit('paymentGateway/setAmountOriginal', this.originalPrice);
    },
    async cancelCurrentIntentPayment() {
      if (this.intentPayment !== null && this.statusCancel.includes(this.intentPayment.status)) {
        await post("credit-order/cancel-payment-intent", {id: this.intentPayment.id, origin: 'stripe'}, true);
        this.intentPayment.status = 'canceled'
      }
    },
    async getInformationPaymentIntent() {
      const {data} = await post("credit-order/get-payment-intent", {id: this.intentPayment.id, origin: 'stripe'}, true);
      this.intentPayment = data;
    },
    async initialize(type) {
      const store = localStorage.getItem('tvs-user')
      let storeD = decrypt(store)
      storeD = JSON.parse(storeD)
      const dataBody = {
        orderId: this.orderId,
        dealerId: this.dealerId,
        email: storeD.email,
        amount: this.amount,
        fee: this.extraFeePaymentMethod,
        description: this.description,
        items: this.items,
        payment_method_types: [type.toString()],
        origin: 'stripe',
      }
      this.$store.commit('paymentGateway/setCompleteDataPayment', dataBody);
      this.$store.commit('paymentGateway/setAmountOriginal', this.originalPrice);
      if (this.intentPayment === null) {
        await this.createIntent(dataBody)
      } else {
        await this.getInformationPaymentIntent(this.intentPayment.id)
        if (this.intentPayment.status === 'canceled') {
          await this.createIntent(dataBody)
        } else {
          this.clientSecret = this.intentPayment.reference
        }
      }
      const appearance = {theme: 'flat',};
      this.elements = stripe.elements({appearance, clientSecret: this.clientSecret});
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#payment-element");
      setTimeout(() => {
        this.showOverlay = false
      }, 3000)
    },
    async createIntent(dataBody) {
      dataBody.amount = this.recalculatePrice.amount
      const {data} = await post("credit-order/create-payment-intent", dataBody, true);
      this.clientSecret = await data.reference;
      this.intentPayment = data
    },
    filterMethodPayment(type) {
      if (this.intentPayment !== null) {
        if (this.intentPayment.status !== 'canceled') {
          Object.keys(this.controlPaymentMethods).forEach(item => {
            this.controlPaymentMethods[item] = (item.toString() !== type)
          })
        }
      }
    },
    async handleSubmit() {
      this.disabled = true;
      const {error} = await stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}/admin/dashboard/credit-order-detail/${this.orderId}`,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        await this.showMessage('Something went wrong', 'Your payment was not successful, please try again.', 'error')
        this.disabled = false;
      } else {
        await this.showMessage('Something went wrong', 'Your payment was not successful, please try again.', 'error')
        this.disabled = false;
      }
    },
    async showMessage(title, text, icon) {
      await this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
      });
    },
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
      if (!clientSecret) {
        return;
      }
      const {paymentIntent} = await stripe.retrievePaymentIntent(clientSecret);
      switch (paymentIntent.status) {
        case "succeeded":
          break;
        case "processing":
          break;
        case "requires_payment_method":
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #c93131; /* Blue */
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
}

.item_payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  text-align: center;
}

.line_bottom {
  border-radius: 0 0 10px 10px;
  border-bottom: black 0.5px solid;
  width: 80%;
}
</style>
