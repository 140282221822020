<template>
    <div>
        <p style="padding-bottom: 2.4em">
            Normal (SEPA) bank transfers are instant and without additional charges. You do not need to waste
            commissions or fees which are involved in credit card or other payment methods.
        </p>
        <transferwise-payment-info :order="order" :show-address="true"/>
    </div>
</template>

<script>
import TransferwisePaymentInfo from "@/components/PaymentsGateway/TransferWise_Payment_Info";

export default {
  name: "TransferWise_C1",
    components:{
        TransferwisePaymentInfo
    }
}
</script>

<style scoped>

</style>
