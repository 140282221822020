<template>
  <b-card body-class="pt-0" v-if="order.payments.length">
    <template #header>
      <h5 class="text-dark">Payment Summary</h5>
    </template>
    <b-list-group>
      <b-list-group-item>
        <div class="d-flex justify-content-between">
          <b>Fee Payment Method: </b>
          <span class="numbers"><currency-price-view :amount="order.extraFee" :currency-details="currencyDetails" no-switch/></span>
        </div>
      </b-list-group-item>
      <b-list-group-item v-for="payment of order.payments">
        <div class="d-flex justify-content-between">
          <b>Date:</b>
          <span>{{ payment.createdAt|localeDate }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <b>Note:
              <span v-b-modal.modal-1 v-if="$userCan(['administrator'])" @click="selectPayment(payment.id, payment.comment)"><i class="material-icons">edit</i></span>
            </b>
          </div>
          <div class="text_extra_large">
            <span class="text_extra_large">{{ payment.comment }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <b>Paid: </b>
          <span class="numbers"><currency-price-view :amount="payment.amount" :currency-details="currencyDetails"
                                                     no-switch/></span>
        </div>
        <div class="d-flex justify-content-between">
          <b>Total Credits:</b>
          <span class="numbers"><credits-view :amount="payment.amountCredits"
                                              :currency-details="order.currencyDetails"/></span>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-list-group flush class="mt-3" v-if="totalPending > 0">
      <b-list-group-item class="d-flex justify-content-between">
        <span>Total Paid</span>
        <strong class="numbers">
          <currency-price-view :amount="totalPaid" :currency-details="currencyDetails" no-switch/>
        </strong>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between">
        <span>Pending</span>
        <strong class="numbers">
          <currency-price-view :amount="totalPending" :currency-details="currencyDetails" no-switch/>
        </strong>
      </b-list-group-item>
    </b-list-group>
    <div class="mt-3" style="display: flex; justify-content: space-around">
      <b-button v-if="isOwner && totalPending > 0" variant="danger" @click="$refs.confirmPayment.open(order, totalPending)">NEW PAYMENT</b-button>
      <b-button v-if="isClient  && showBtnConfirm && totalPending > 0" variant="danger" @click="confirmNewPayment()">CONFIRM NEW PAYMENT</b-button>
    </div>
    <ConfirmPayment ref="confirmPayment" @confirmed="getData"/>

    <b-modal v-if="$userCan(['administrator'])" id="modal-1" title="Edit comment" hide-footer>
      <div class="d-flex justify-content-between">
        <b-form-input placeholder="Edit note" v-model="commentPaymentSelected"></b-form-input>
        <b-button variant="danger" @click="editComment">Edit</b-button>
      </div>
    </b-modal>

  </b-card>
</template>

<script>
import CreditsView from "@/components/common/CreditsView";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import ConfirmPayment from "@/views/dashboard/credit-order-detail/ConfirmPayment";
import {post} from "@/services/api";
import {creditOrderNotifyPayment} from "@/services/endpoint.json";

export default {
  name: "PaymentSummary",
  components: {
    ConfirmPayment,
    CreditsView,
    CurrencyPriceView,
  },
  props: {
    order: {
      default: {}
    },
    currencyDetails: {
      default: null
    },
    totalPending: {
      default: 0
    },
    totalPaid: {
      default: 0
    },
    isOwner: {
      default: false
    },
    isClient: {
      default: false
    },
    showBtnConfirm: {
      default: true
    }
  },

  data: () => ({
    showInstruction: false,
    showInvoice: false,
    paymentSelected: null,
    commentPaymentSelected: null,
  }),

  methods: {
    async confirmNewPayment() {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to confirm New Payment?',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async value => {
        if (value.isConfirmed) {
          this.showBtnConfirm = false
          await post(creditOrderNotifyPayment, {id: this.order.id}, true);
        }
      }).catch(e => {
      })
    },

    selectPayment(id, comment) {
      this.paymentSelected = id
      this.commentPaymentSelected = comment
    },

    async editComment() {
      if (!!this.paymentSelected && !!this.commentPaymentSelected && this.commentPaymentSelected.length<= 250) {
        await post("credit-order/update-comment-payment", {
          id: this.paymentSelected,
          comment: this.commentPaymentSelected
        })
        this.commentPaymentSelected = null
        this.paymentSelected = null
        location.reload()
      }
    }
  }
}
</script>

<style scoped>
.text_extra_large {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  min-width: 1px;
  text-align: end;
}
</style>
