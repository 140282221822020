import Vue from 'vue';
import '@/assets/scss/vue-tables.scss';
import {get, post} from '@/services/api';
import {creditOrderView,} from '../../../services/endpoint.json';
import InvoiceView from './InvoiceView.vue';
import {
  CREDIT_ORDERS_STATUS,
  INVOICE_INFORMATION_DEFUALT,
  invoicesTitlesDistributors,
  invoicesTitlesTvs
} from '@/constants';
import ConfirmPayment from '@/views/dashboard/credit-order-detail/ConfirmPayment';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import {decrypt} from '@/helpers/encrypt';
import {userCan} from '@/utils';
import {mapGetters, mapState} from 'vuex';
import Stripe from '@/components/PaymentsGateway/Stripe'
import Binance from '@/components/PaymentsGateway/Binance'
import Revolut from '@/components/PaymentsGateway/Revolut'
import Transferwise from '@/components/PaymentsGateway/TransferWise'
import TransferwisePaymentInfo from '@/components/PaymentsGateway/TransferWise_Payment_Info'
import Payoneer from '@/components/PaymentsGateway/Payoneer.vue'
import OrderSummary from './OrderSummary'
import PaymentSummary from './PaymentSummary'

const countriesList = [
  "AT", "Belgium", "BE", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"
]

export default Vue.extend({
  name: 'CreditOrderDetail',
  components: {
    InvoiceView,
    ConfirmPayment,
    CreditsView,
    CurrencyPriceView,
    Stripe,
    Binance,
    Revolut,
    OrderSummary,
    PaymentSummary,
    Transferwise,
    TransferwisePaymentInfo,
    Payoneer,
  },
  data: () => ({
    idSesion: null,
    isOwner: false,
    isClient: false,
    CREDIT_ORDERS_STATUS: CREDIT_ORDERS_STATUS,
    order: null,
    company: {
      address: '',
      nameCompany: '',
      legalRepresentative: '',
      numberCompany: '',
      emailCompany: '',
      phone: '',
      city: '',
      country: '',
      zipCode: '',
      website: '',
      userId: ''
    },
    showFormCompany: false,
    files: [],
    companyOwner: INVOICE_INFORMATION_DEFUALT,
    paymentCompanyOwner: null,
    canAcceptOrder: false,
    ownerHaveBalance: true,
    haveParent: false,
    showInstruction: false,
    showInvoice: false,
    invoiceTitles: invoicesTitlesDistributors(),
    showButtonDownload: false,
    nameCompanyOwner: null,
    showBtnConfirm: true,
    showAddressSection: false,
    descriptionOrder: null,
    intentPayment: null,
    isEuropa: false,
    showVatNumber: false,
    vatNumber: null,
    customInvoiceConfiguration: {
      userId: null,
      companyName: {status: true, value: ''},
      vatNumber: {status: false, value: ''},
      attentionTo: {status: true, value: ''},
      address1: {status: true, value: ''},
      address2: {status: true, value: ''},
      city: {status: true, value: ''},
      zipCode: {status: true, value: ''},
      country: {status: true, value: ''}
    }
  }),
  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol']),
    ...mapState(['paymentGateway']),
    ...mapGetters('paymentGateway', ['recalculatePrice']),
    totalPaid() {
      let total = 0;
      this.order.payments.forEach(payment => total += payment.amount);
      return total;
    },
    totalPending() {
      return this.order.price - this.totalPaid;
    },
    currencyDetails() {
      let currency = this.order.currencyDetails;
      if (this.order.ownerId == null) {
        currency = this.$store.state.geo.tvsCurrency
      }
      return currency
    }
  },
  async created() {
    await this.$store.dispatch('buySale/loadInformation');
  },
  mounted() {
    this.isEuropa = countriesList.includes(this.$store.state.geo.userCountry.alpha2Code)
    this.getData()
    let temp = localStorage.getItem('admin-id');
    temp = decrypt(temp);
    this.idSesion = temp;
  },
  watch: {
    information: {
      handler: function () {
      },
      deep: true
    },
    '$route.params'() {
      this.getData().then();
    }
  },
  methods: {
    async getData() {
      const id = this.$route.params.id;
      const {data} = await post(creditOrderView, {id}, true);
      this.haveParent = data.haveParent;
      data.order.extraFee = Number(data.order.extraFee)
      this.order = data.order;
      this.order.orderCancelled = data.orderCancelled
      this.intentPayment = data.intentPayment;
      this.descriptionOrder = `${this.order.credit} software credits. incl. ${this.order.discount}% discount`
      this.nameCompanyOwner = data.companyParent;
      this.invoiceTitles = (this.order.ownerId === null) ? invoicesTitlesTvs() : invoicesTitlesDistributors();
      this.showButtonDownload = (this.order.ownerId === null);
      this.showAddressSection = (this.order.ownerId === null);
      this.validatePanel();
      this.validateOwnerBalance(data.ownerBalance);
      this.validateInfoCompany(data);
      this.setDataStore();
      this.files = data.files;
      return true
    },
    validatePanel() {
      if (this.order.status.toString() === CREDIT_ORDERS_STATUS.Payment_Confirmed_Admin ||
        this.order.status.toString() === CREDIT_ORDERS_STATUS.Partially_Paid ||
        this.order.payments.length) {
        this.showInstruction = false;
        this.showInvoice = true;
      } else if (this.order.status.toString() === CREDIT_ORDERS_STATUS.New || this.order.status.toString() === CREDIT_ORDERS_STATUS.Payment_Confirmed_Customer) {
        this.showInstruction = true;
        this.showInvoice = false;
      } else if (this.order.status.toString() === CREDIT_ORDERS_STATUS.Offer) {
        this.showInstruction = false;
        this.showInvoice = false;
      }
    },
    validateOwnerBalance(balance) {
      this.ownerHaveBalance = (Number(balance) >= Number(this.order.credit));
      this.canAcceptOrder = (Number(this.idSesion) === Number(this.order.userId));
      if ((Number(this.order.ownerId) === Number(this.idSesion)) || (this.order.ownerId == null && userCan(['administrator']))) {
        this.isOwner = true;
      }
      if ((Number(this.order.userId) === Number(this.idSesion))) {
        this.isClient = true;
      }
    },
    validateInfoCompany(data) {
      if (data.companyOwner != null) {
        if (data.companyOwner.extraData != null) {
          this.companyOwner = JSON.parse(data.companyOwner.extraData);
        }
        if (data.companyOwner.payment != null) {
          this.paymentCompanyOwner = JSON.parse(data.companyOwner.payment);
        }
      }
      this.validateCustomInvoiceInformation(data.company)
    },
    async validateCustomInvoiceInformation(company) {
      this.company = company;
      this.company.userId = this.order.userId;
      const {data} = await get(`invoice-numeration/get-custom-information?id=${this.order.userId}`, null, true)
      if (!!data) {
        this.customInvoiceConfiguration = data.information
        this.company.address = data.information.address1.value
        this.company.address2 = data.information.address2.value
        this.company.legalRepresentative = data.information.attentionTo.value
        this.company.city = data.information.city.value
        this.company.nameCompany = data.information.companyName.value
        this.company.country = data.information.country.value
        this.company.vatNumber = data.information.vatNumber.value
        this.company.zipCode = data.information.zipCode.value
      }
    },
    showModalInvoice() {
      this.$refs['modal-invoice'].show()
    },
    setDataStore() {
      const store = localStorage.getItem('tvs-user')
      let storeD = decrypt(store)
      const dataBody = {
        orderId: this.orderId,
        dealerId: this.order.userId,
        email: storeD.email,
        amount: this.order.price,
        fee: 0,
        description: `${this.order.credit} software credits. incl. ${this.order.discount}% discount`,
        items: [{id: 'software credits'}],
        payment_method_types: [],
        origin: null,
        percentageFee: 0
      }
      this.$store.commit('paymentGateway/setCompleteDataPayment', dataBody);
      this.$store.commit('paymentGateway/setIsEuropa', this.isEuropa);
      this.$store.commit('paymentGateway/setAmountOriginal', this.originalPrice);
    },
  },
});

